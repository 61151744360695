export const API_URL = "https://apidwmdev.appgrowthcompany.com/api/v1/user/"; // development
// export const API_URL = "https://api.krafo.com/api/v1/user/"; //live

export const WORDPRESS_URL = "https://cms-krafo.appgrowthcompany.com/"; //development
// export const WORDPRESS_URL = "https://cms.krafo.com/"; //live
export const WORDPRESS_USERNAME = "ck_1a791a6311f3c914876cfd06a37ed2f4";
export const WORDPRESS_PASSWORD = "cs_145ac99b82ea8447be63f4a3082e556f";

export const GOOGLE_API_KEY = "AIzaSyD_dWCohWCj8HXfT4sQMHn1kvyelqI5o-A";

// for dev
export const STRIPE_TEST_KEY =
  "pk_test_51Oab3TLfkKXBc4GEkcdwuxYB5kueVaWQjRyQPNsTVdbmcs75VXfoHYdRm8c31EjN6JD3S4lcP3WocEhs9Qe3VC8J00BGJRCcoI";

// // for staging
// export const STRIPE_TEST_KEY =
//   "pk_live_51Oab3TLfkKXBc4GEG8TBJh0n5c7LkEJReWJTlzzZLHZ4szAXcDFMCaInUmZkA9m6EyF1BGr7f5CVlR561eRfRW4R00F9Qqu2JI";

export const END_POINTS = {
  signUp: "sendOtp",
  verifyotp: "verifyotp",
  login: "login",
  mediaUpload: "upload",
  profile: "profile",
  forgotPassword: "forgotpassword",
  resetPassword: "setPassword",
  getProfile: "getProfile",
  justSendOtp: "justSendOtp",
  justVerifyOtp: "justVerifyOtp",
  changepassword: "changepassword",
  logout: "logout",
  deleteAccount: "deleteAccount",
  socialLogin: "socialLogin",
  updateGuestProfile: "updateGuestProfile",

  // home
  dashboard: "dashboard",
  banner: "banner",
  recommended: "recommended",
  featuredProd: "featuredProd",
  recentProduct: "recentProduct",

  recentProducts: "recentProducts",
  guestLogin: "guestLogin",
  globalSearch: "globalSearch",
  getProduct: "getProduct",
  getProfileById: "getProfileById",
  addWishlist: "addWishList",
  getWishlist: "getWishList",
  category: "category",
  getProductsByCategoryId: "getProductsByCategoryId",
  subCategory: "subCategory",
  follow: "follow",
  followList: "followList",
  makeAnOffer: "makeAnOffer",
  saveSearchHistory: "saveSearchHistory",
  searchHistory: "searchHistory",
  clearSearch: "clearSearch",
  featuredProducts: "featuredProducts",
  subscribeNewsletter: "subscribeNewsletter",

  //add product
  addProduct: "addProduct",
  getMyProduct: "getMyProduct",
  getProductById: "getProduct",
  updateProduct: "updateProduct",
  deleteProduct: "deleteProduct",
  reportProduct: "reportProduct",

  //address
  addAddress: "addAddress",
  getMyAddress: "getMyAddress",
  updateAddress: "updateAddress",
  deleteAddress: "deleteAddress",
  selectAddress: "selectAddress",

  //cms
  cms: "cms",
  faq: "faq",
  review: "review",
  report: "reportUser",
  getBoosterPlans: "getBoosterPlans",
  buyBooster: "buyBooster",
  getMyBoosterPlan: "getMyBoosterPlan ",
  notification: "notification",

  //chat
  chatList: "chatList",
  clearChat: "clearChat",
  offerResponse: "offerResponse",

  //payment
  saveCard: "saveCard",
  getCard: "getCard",
  deleteCard: "deleteCard",

  //statesList
  statesList: "statesList",
  selectState: "selectState",

  //categoryFilter
  getMyProductFilter: "getMyProductFilter",
  searchOnNextPage: "searchOnNextPage",
  relatedProducts: "relatedProducts",
  sellerForm: "sellerForm",
  getSeller: "getSeller",
  mostlyViewed: "mostlyViewed",
  mostlySearched: "mostlySearched",
  newArrival: "newArrival",

  //seller
  sellerSendOtp: "sellerSendOtp",
  sellerVerifyotp: "sellerVerifyotp",

  //blogs
  blogs: "wp-json/custom/v1/blogs",
  seasonalOffers: "wp-json/custom/v1/page",
  blogsByCategory: "wp-json/custom/v1/categoryblog",
  blogById: "wp-json/custom/v1/blog",
  topManufacturers: "wp-json/custom/v1/manufacturers",

  topDeals: "topDeals",
  hotDeals: "hotDeals",
};
